import Notif from "@/classes/Notif"
import Session from "@/classes/Session"
import Univers from "@/classes/Univers"
import Team from "@/classes/Team"
import Profile from "@/classes/Profile"

const notifier = {
    async sendToUser(profileId, type, data = {}){
        let notif = new Notif(null, profileId, type, data)
        notif.save()

        return
    },

    async sendToTeam(teamId, type, data = {}){
        let team = await Team.getById(teamId)

        for(let member of team.members){
            let profile = await Profile.getById(member.id)
            this.sendToUser(profile.id, type, data)
        }

        return
    },

    async sendToUniverse(universId, type, data = {}){
        let teams = await Team.getByUnivers(universId)

        for(let team of teams){
            this.sendToTeam(team.id, type, data)
        }

        return
    },

    async sendToSession(sessionId, type, data = {}){
        let universes = await Univers.getBySession(sessionId)

        for(let univers of universes){
            this.sendToUniverse(univers.id, type, data)
        }

        return
    },

    async cleanOldNotif(sessionId, dataId){
        let universes = await Univers.getBySession(sessionId)
        
        let teams = []
        for(let univers of universes){
            teams = teams.concat(await Team.getByUnivers(univers.id))
        }

        let profiles = []
        for(let team of teams){
            profiles = profiles.concat(team.members)
        }

        let notifs = []
        for(let profile of profiles){
            notifs = notifs.concat(await Notif.getByProfile(profile.id))
        }

        for(let notif of notifs){
            if(notif.data.id == dataId){
                notif.delete()
            }
        }
    }
}

export default notifier