<template>
  <span>
    <v-card class="pa-5">
      <v-card-title primary-title class="justify-center">
        <h1 class="text-center mb-3" v-text="title"></h1>
      </v-card-title>
      <v-row
        class="d-flex flex-row align-center justify-center"
        v-if="profile.role != 'User'"
      >
        <v-col cols="12" md="4">
          <v-select
            :items="sessions"
            item-text="name"
            return-object
            label="Sélectionnez une session"
            v-model="selectedSession"
          ></v-select>
        </v-col>
      </v-row>
      <v-tabs
        v-model="currentTab"
        color="primary"
        centered
        v-if="selectedSession"
      >
        <v-tab v-if="profile.role != 'User'">
          <v-icon color="accent" class="mr-2">mdi-cog</v-icon>
          <span class="accent--text">Gerer les fichiers</span>
        </v-tab>
        <template v-for="file in files">
          <v-tab
            v-if="new Date(file.date).getTime() <= new Date().getTime()"
            @click="cleanNotif(notifs[file.id])"
          >
            <span v-text="file.name"></span>
            <v-badge color="accent" v-if="notifs[file.id]">
              <span slot="badge" v-text="'!'"></span>
            </v-badge>
          </v-tab>
          <v-tab class="error--text" v-else-if="profile.role != 'User'">
            <span v-text="file.name"></span>
          </v-tab>
        </template>
      </v-tabs>
      <v-tabs-items v-model="currentTab" class="pt-3" v-if="selectedSession">
        <v-tab-item v-if="profile.role != 'User'">
          <v-row class="mt-3">
            <v-col cols="12" md="6" offset="0" offset-md="3">
              <h2 class="text-center">Ajouter un fichier</h2>
              <v-text-field
                color="primary"
                label="Nom"
                v-model="newFile.name"
              ></v-text-field>
              <v-text-field
                type="datetime-local"
                label="Date de publication"
                v-model="newFile.date"
              ></v-text-field>
              <v-file-input
                color="primary"
                accept=".pdf"
                truncate-length="32"
                label="Fichier PDF"
                :prepend-icon="null"
                v-model="newFile.file"
              ></v-file-input>
              <span class="d-flex flex-row align-center justify-center">
                <v-btn color="primary" outlined @click="addDocument"
                  >Ajouter</v-btn
                >
              </span>
            </v-col>
            <v-col cols="12" md="6" offset="0" offset-md="3" class="my-5">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="6" offset="0" offset-md="3">
              <h2 class="text-center">Gerer les fichiers</h2>
              <v-data-table :headers="fileHeaders" :items="files">
                <template v-slot:item.date="{ item }">
                  <span
                    v-text="new Date(item.date).toLocaleString()"
                    v-if="new Date(item.date).getTime() <= new Date().getTime()"
                  ></span>
                  <span
                    v-text="new Date(item.date).toLocaleString()"
                    class="error--text"
                    v-else
                  ></span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn color="primary" icon @click="openEditDialog(item)">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn color="error" icon @click="deleteFile(item)">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          v-for="file in files"
          v-if="
            new Date(file.date).getTime() <= new Date().getTime() ||
            profile.role != 'User'
          "
        >
          <span class="d-flex flex-row align-center justify-center">
            <iframe
              :src="file.url"
              frameborder="0"
              style="
                width: 100%;
                height: 100%;
                max-width: 80vw;
                min-height: 80vh;
              "
            ></iframe>
          </span>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog
      v-model="editDialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          <h2>Renommer le document</h2>
        </v-card-title>
        <v-card-text>
          <v-text-field label="Nom" v-model="editName"></v-text-field>
          <v-text-field
            type="datetime-local"
            label="Date de publication"
            v-model="editDate"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="closeEditDialog()"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="renameFile()">Sauvegarder</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";

import Swal from "sweetalert2/dist/sweetalert2.js";
import notifier from "@/assets/functions/notifier.js";

import Session from "@/classes/Session";
import Univers from "@/classes/Univers";
import Team from "@/classes/Team";
import File from "@/classes/File.js";
import Notif from "@/classes/Notif";

export default {
  name: "FileDisplayer",
  props: ["user", "profile", "category", "title"],
  setup(props) {
    return {
      storage: getStorage(),
    };
  },
  data() {
    return {
      unsub: [],
      sessions: [],
      currentTab: 0,
      selectedSession: null,
      fileHeaders: [
        {
          text: "Nom",
          value: "name",
          sortable: true,
        },
        {
          text: "Date de publication",
          value: "date",
          sortable: true,
        },
        {
          text: "",
          value: "actions",
          sortable: true,
        },
      ],
      files: [],
      newFile: {
        name: "",
        file: null,
        date: null,
      },
      editName: "",
      editDate: "",
      editFile: null,
      editDialog: false,
      notifs: {},
    };
  },
  async created() {
    if (this.profile.role != "User") {
      this.unsub.push(
        Session.listenOnlySession((sessions) => {
          sessions.sort((a, b) => b.date - a.date);
          this.sessions = sessions;
        })
      );
    } else {
      let currentTeam = await Team.getById(this.profile.team);
      let currentUnivers = await Univers.getById(currentTeam.univers);
      this.selectedSession = await Session.getById(currentUnivers.session);

      this.unsub.push(
        Notif.listenByProfile(this.profile.id, (notifs) => {
          let tmp_notifs = {};

          for (let notif of notifs) {
            if (notif.type == this.category) {
              tmp_notifs[notif.data.id] = notif;
            }
          }
          this.notifs = tmp_notifs;
        })
      );
    }
  },
  watch: {
    selectedSession: {
      async handler(val) {
        if (val) {
          this.unsub.push(
            File.listenBySession(this.selectedSession.id, async (files) => {
              this.files = [];
              for (let file of files) {
                if (file.category == this.category && file.fileRef) {
                  await getDownloadURL(
                    ref(
                      this.storage,
                      "files/" + this.category + "/" + file.fileRef
                    )
                  ).then((url) => {
                    file.url = url;
                  });
                  this.files.push(file);
                }
              }
              this.files.sort((a, b) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
              });
            })
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    async addDocument() {
      if (
        this.newFile.file &&
        this.newFile.file.constructor.name == "File" &&
        this.newFile.name &&
        this.newFile.date &&
        this.selectedSession
      ) {
        let file = new File(
          null,
          this.selectedSession.id,
          this.category,
          this.newFile.name,
          new Date(this.newFile.date).getTime(),
          null
        );
        file = await file.save();

        let name = this.selectedSession.id + "_" + file.id + ".pdf";
        let fileRef = ref(this.storage, "files/" + this.category + "/" + name);
        await uploadBytes(fileRef, this.newFile.file);
        file.fileRef = name;
        let newFile = await file.save();

        notifier.sendToSession(this.selectedSession.id, this.category, {
          id: newFile.id,
          date: newFile.date,
        });

        this.newFile = {
          name: "",
          file: null,
          date: null,
        };

        this.$forceUpdate();
      }
    },
    async deleteFile(file) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "Voulez vous vraiment supprimer ce challenge ?",
        icon: "question",
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await notifier.cleanOldNotif(this.selectedSession.id, file.id);
          let fileRef = ref(
            this.storage,
            "files/" + this.category + "/" + file.fileRef
          );
          deleteObject(fileRef);
          file.delete();
          this.$forceUpdate();
        }
      });
    },
    openEditDialog(file) {
      let startTime = new Date(file.date);
      let offSetInMilliSeconds = startTime.getTimezoneOffset() * 60 * 1000;
      let UpdatedTime = new Date(startTime - offSetInMilliSeconds)
        .toISOString()
        .slice(0, 16);
      this.editName = file.name;
      this.editDate = UpdatedTime;
      this.editFile = file;
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editName = "";
      this.editDate = "";
      this.editFile = null;
      this.editDialog = false;
    },
    async renameFile() {
      if (
        this.editName &&
        this.editDate &&
        this.editFile &&
        this.editName.trim() != ""
      ) {
        this.editFile.date = this.editDate;
        this.editFile.name = this.editName;
        await this.editFile.save();
        notifier.sendToSession(this.selectedSession.id, this.category, {
          id: this.editFile.id,
          date: this.editFile.date,
        });
        this.$forceUpdate();
        this.closeEditDialog();
      }
    },
    cleanNotif(notif) {
      notif.delete();
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
