<template>
    <span v-if="profile.role != 'User' || (userSession && userSession.displayParameters && userSession.displayParameters.documents)">
        <FileDisplayer :profile="profile" :user="user" :title="title" :category="category"/>
    </span>
</template>

<script>
import FileDisplayer from '@/components/Files/FileDisplayer.vue'

export default {
    name: "Documents",
    props: ["user", "profile", "userSession"],
    components: {
        FileDisplayer
    },
    data(){
        return {
            category: "documents",
            title: "Autres documents",
        }
    },
}
</script>
