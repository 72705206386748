import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "files"

class File{
    constructor(id, session, category, name, date, fileRef){
        this.id = id
        this.session = session
        this.category = category
        this.name = name
        this.date = date
        this.fileRef = fileRef
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let files = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_file = new File(doument.id, data.session, data.category, data.name, data.date, data.fileRef)
            files.push(tmp_file)
        })

        return files
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let file = new File(response.id, data.session, data.category, data.name, data.date, data.fileRef)

        return file
    }

    static async getBySession(sessionId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("session", "==", sessionId))
        let response = await getDocs(documentQuery)

        let files = []

        response.forEach(document => {
            let data = document.data()
            let tmp_file = new File(document.id, data.session, data.category, data.name, data.date, data.fileRef)
            files.push(tmp_file)
        })

        return files
    }

    static async getByCategory(category){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("category", "==", category))
        let response = await getDocs(documentQuery)

        let files = []

        response.forEach(document => {
            let data = document.data()
            let tmp_file = new File(document.id, data.session, data.category, data.name, data.date, data.fileRef)
            files.push(tmp_file)
        })

        return files
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let files = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_file = new File(document.id, data.session, data.category, data.name, data.date, data.fileRef)
                files.push(tmp_file)
            })
            
            if(callback != null){
                callback(files)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let file = new File(snapshot.id, data.session, data.category, data.name, data.date, data.fileRef)
            
            if(callback != null){
                callback(file)
            }
        })
        return unsub
    }

    static async listenBySession(sessionId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("session", "==", sessionId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let files = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_file =  new File(document.id, data.session, data.category, data.name, data.date, data.fileRef)
                files.push(tmp_file)
            })
            
            if(callback != null){
                callback(files)
            }
        })
        return unsub
    } 

    static async listenByCategory(category, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("category", "==", category))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let files = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_file =  new File(document.id, data.session, data.category, data.name, data.date, data.fileRef)
                files.push(tmp_file)
            })
            
            if(callback != null){
                callback(files)
            }
        })
        return unsub
    } 

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                session : this.session,
                category : this.category,
                name : this.name,
                date : this.date,
                fileRef : this.fileRef
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                session : this.session,
                category : this.category,
                name : this.name,
                date : this.date,
                fileRef : this.fileRef
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default File